import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Button from '@material-ui/core/Button';
import "../assets/css/nav.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Nav = () => (
  <StaticQuery
    query={graphql`
      query {
        strapiGlobal {  
          WelcomeImage{
            url
          }
        }
      }
    `}
    render={(data) => (
        <nav className="nav-container">
          <div className="navbar-center">
            <Link to="/">
              <img className="navbar-logo" src={ data.strapiGlobal.WelcomeImage.url } alt="" /> 
            </Link>            
          </div>
          {/* <div className="navbar-right">
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { window.location.replace("https://www.instagram.com/scubskitchen/") }}>
              <InstagramIcon />
            </IconButton>
          </div>              */}
        </nav>
    )}
  />
);

export default Nav;
