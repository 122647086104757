import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Nav from "./nav";
import Seo from "./seo";
import { Link } from "gatsby";
import IconButton from '@material-ui/core/IconButton';
import InstagramIcon from '@material-ui/icons/Instagram';
import "../assets/css/layout.css";

const Layout = ({ children, seo }) => (
  <StaticQuery
    query={graphql`
      query {
        strapiHomepage {
          # WelcomeImage {
          #   publicURL
          # }
          WelcomeMessage
          hero{
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Seo seo={seo} />
        <Nav />
        <div className="homepage-nav-wrapper">
          <nav className="homepage-nav">
            <Link to="/" activeClassName="active-link">Home</Link>
            {/* <Link to="/classes">Classes</Link> */}            
            <Link to="/gallery" activeClassName="active-link">Gallery</Link>
            <Link to="/pricing" activeClassName="active-link">Pricing</Link>
            <Link to="/orderrequest" activeClassName="active-link">Order Request</Link>
            <Link to="/faq" activeClassName="active-link">FAQ</Link>
            <Link to="/contact" activeClassName="active-link">Contact</Link>
            <Link to="/aboutme" activeClassName="active-link">About Me</Link>
            {/* <Link to="/shop" activeClassName="active-link">Shop</Link> */}
          </nav>
        </div>
        <main>{children}</main>
        <br />
        <div className="footer">Copyright © 2022 | 
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { window.location.replace("https://www.instagram.com/scubskitchen/") }}>
              <InstagramIcon />
            </IconButton>
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
