import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ seo = {}, lang }) => {

  const data = useStaticQuery(query);
  const metaData = data.site.siteMetadata;
  //console.log(metaData);
  // console.log(data.site.siteMetaData);
  // Merge default and page-specific SEO values
  // const fullSeo = { ...defaultSeo, ...seo };

  const getMetaTags = () => {
    const tags = [];

    if (metaData.title) {
      tags.push(
        {
          property: "og:title",
          content: metaData.title,
        },
        {
          name: "twitter:title",
          content: metaData.title,
        }
      );
    }
    if (metaData.description) {
      tags.push(
        {
          name: "description",
          content: metaData.description,
        },
        {
          property: "og:description",
          content: metaData.description,
        },
        {
          name: "twitter:description",
          content: metaData.description,
        }
      );
    }
    if (metaData.image) {
      const imageUrl =
        (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
        metaData.image;
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      );
    }
    if (metaData.type) {
      tags.push({
        property: "og:type",
        content: metaData.type,
      });
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" });

    return tags;
  };

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={metaData.title}
      link={[
        {
          rel: "icon",
          href: metaData.image.publicURL,
        }
      ]}
      meta={metaTags}
    />
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  lang: `en`,
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query {
    site{
      siteMetadata {
        title
        description
        author
        keywords
        url
        image
        type
      }
    }
    strapiGlobal {
      siteName      
      defaultSeo {
        url
        Title
      }
    }
  }
`;
